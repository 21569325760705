import styled from 'styled-components';

interface TitleProps {
  fontSize?: string;
  mobileFontSize?: string;
  margin?: string;
  width?: string;
  textAlign?: string;
}

export const CourseItemText = styled.p`
  font-size: ${(props: TitleProps) => props.fontSize || '1rem'};
  font-weight: bold;
  width: ${(props: TitleProps) => props.width || '100%'};
  text-align: ${(props: TitleProps) => props.textAlign || 'auto'};
  font-family: poppinsmedium;
  margin: ${(props: TitleProps) => props.margin || '5px 0'};
  align-self: end;
  justify-self: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  @media ${({ theme }) => theme.device.mobileL} {
    text-align: left;
    flex: 1 1 content;
    max-width: 340px;
    align-self: center;
    font-size: 0.85rem;
    margin-right: 1%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`;

export const CourseItemSubtext = styled(CourseItemText)`
  font-size: 0.85rem;
  font-family: poppinsregular;

  @media ${({ theme }) => theme.device.mobileL} {
    max-width: 85px;
    min-width: 85px;
  }
`;

export const ScoreSubText = styled(CourseItemText)`
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.2rem;
  text-align: right;
  font-family: poppinsregular;
  @media ${({ theme }) => theme.device.tablet} {
    flex-shrink: 0;
    font-size: 0.85rem;
  }
  @media ${({ theme }) => theme.device.mobileL} {
    font-size: 0.9rem;
  }
`;

export const Title = styled.h1`
  font-size: ${(props: TitleProps) => props.fontSize || '24px'};
  font-family: poppinsregular;
  color: ${({ theme }) => theme.color.darkBlue};
  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${(props: TitleProps) => props.fontSize || '18px'};
    text-align: center;
  }
`;
