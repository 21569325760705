import { CenteredFlexContainer } from '@/styles/Containers';
import React, { useState } from 'react';
import { CarouselCard } from '@/components/Carousel/CarouselCard';
import { CarouselButton, IconButton } from '@/styles/Buttons';
import arrowRight from '@/assets/icons/ArrowRight.svg';
import arrowLeft from '@/assets/icons/ArrowLeft.svg';
import useSize from '@/utils/useSize';
import useInterval from '@/utils/useInterval';
import { CustomizableHeading } from '@/styles/Text';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
  @media ${({ theme }) => theme.device.laptop} {
    padding: 0 1%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 2%;
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

export interface InformationCarouselProps {
  items: { picture: string; title: string; text: string; altText: string }[];
}

export const InformationCarousel = ({ items }: InformationCarouselProps) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [shouldSwapCard, setShouldSwapCard] = useState(true);
  useInterval(() => {
    if (shouldSwapCard) {
      setCurrentCard((prevCard) => (prevCard + 1) % items.length);
    } else {
      setShouldSwapCard(true);
    }
  }, 10000);

  const { isMobile } = useSize(760);

  return (
    <div style={{ padding: '2rem 0', backgroundColor: '#F1FAEE', textAlign: 'center' }}>
      <CustomizableHeading headingLevel='h2' fontSize='2em' margin='1rem 0'>
        Hvordan EMNR fungerer
      </CustomizableHeading>
      <GridContainer>
        {isMobile && (
          <IconButton
            onClick={() => {
              setCurrentCard((prevCard) => (prevCard == 0 ? 2 : prevCard - 1));
              setShouldSwapCard(false);
            }}
          >
            <img src={arrowLeft} alt='Arrow left' width={'40rem'} height={'auto'} />
          </IconButton>
        )}
        {isMobile ? <CarouselCard {...items[currentCard]} /> : items.map((item, index) => <CarouselCard key={index} {...item} />)}
        {isMobile && (
          <IconButton
            onClick={() => {
              setCurrentCard((prevCard) => (prevCard + 1) % items.length);
              setShouldSwapCard(false);
            }}
          >
            <img src={arrowRight} alt='Arrow right' width={'40rem'} height={'auto'} />
          </IconButton>
        )}
      </GridContainer>

      <CenteredFlexContainer>
        {isMobile &&
          items.map((item, index) => {
            return (
              <CarouselButton
                style={{
                  backgroundColor: currentCard == index ? '#457B9D' : '#1E3859',
                  opacity: currentCard == index ? '100%' : '19%',
                }}
                key={item.text}
                onClick={() => {
                  setCurrentCard(index);
                  setShouldSwapCard(false);
                }}
              />
            );
          })}
      </CenteredFlexContainer>
    </div>
  );
};
