import React from 'react';

import styled from 'styled-components';
import { NavbarButton } from '@/styles/Buttons';
import '@/utils/dropdown.css';
import Searchfield from './Searchfield';
import DropdownCustom from './DropdownCustom';
import { useNavigate } from 'react-router-dom';
const SortingContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
`;

const NavBarButtonContainer = styled.div`
  flex: 0 0 10%;
  display: flex;
`;
interface SearchbarProps {
  setSearchFieldChanged: React.Dispatch<React.SetStateAction<boolean>>;
  searchFieldChanged: boolean;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
  setSortOption: React.Dispatch<React.SetStateAction<string>>;
  sortOption: string;
}
const Searchbar = ({ setSearchFieldChanged, searchFieldChanged, setInputValue, inputValue, setSortOption, sortOption }: SearchbarProps) => {
  const navigate = useNavigate();

  function submitSearch() {
    navigate({
      pathname: '/search',
      search: `?inputValue=${inputValue}&sortOption=${sortOption}`,
    });
  }
  return (
    <SortingContainer>
      <Searchfield
        onEnterKeyPress={submitSearch}
        setInputValue={setInputValue}
        inputValue={inputValue}
        setSearchFieldChanged={setSearchFieldChanged}
        searchFieldChanged={searchFieldChanged}
      ></Searchfield>
      <DropdownCustom setSortOption={setSortOption} sortOption={sortOption} />
      <NavBarButtonContainer>
        <NavbarButton onClick={submitSearch}>Søk</NavbarButton>
      </NavBarButtonContainer>
    </SortingContainer>
  );
};

export default Searchbar;
