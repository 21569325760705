import React from 'react';
import PencilIcon from '@/assets/icons/create.svg';
import styled from 'styled-components';

interface EditReviewProps {
  id: number;
  setIdCommentEdit: React.Dispatch<React.SetStateAction<number>>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}
const PencilBtn = styled.img`
  width: 30px;
  margin-right: 10px;
  margin-left: auto;
  cursor: pointer;
  :hover {
    filter: invert(48%) sepia(96%) saturate(3381%) hue-rotate(328deg) brightness(86%) contrast(112%);
  }
`;

const EditReview = ({ id, setIdCommentEdit, setEdit }: EditReviewProps) => {
  const editReview = () => {
    setIdCommentEdit(id);
    setEdit(true);
  };
  return <PencilBtn src={PencilIcon} onClick={editReview} />;
};
export default EditReview;
