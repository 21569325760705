import React from 'react';
import styled from 'styled-components';

const RecruitmentContainer = styled.div`
  width: 100%;
  font-size: 0.9rem;
  padding-bottom: 1rem;
  font-family: poppinsregular;
  height: min-content;
  position: sticky;
  background-color: #ffcb80;
  z-index: 99;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const RecruitmentInfo = styled.p`
  padding: 0;
  margin-bottom: 0;
`;

const RecruitmentLinkContainer = styled.p`
  padding: 0;
  margin-bottom: 0;
  align-items: center;
`;

const RecruitmentLink = styled.a`
  margin-left: 5px;
  margin-right: 5px;
  font-family: poppinsmedium;
  color: ${({ theme }) => theme.color.darkBlue};
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: ${({ theme }) => theme.color.red};
  }
`;

const Recruitment = () => {
  return (
    <RecruitmentContainer>
      <RecruitmentInfo>Er du interessert i programmering og design? Bli med på å lage og designe IT-løsninger for Studentmediene!</RecruitmentInfo>
      <RecruitmentLinkContainer>
        Trykk for å søke på
        <RecruitmentLink target='_blank' href='https://samfundet.no/verv/1760-it-utvikler'>
          IT-utvikler
        </RecruitmentLink>
        og
        <RecruitmentLink target='_blank' href='https://samfundet.no/verv/1761-ui-ux-designer'>
          UI/UX-designer
        </RecruitmentLink>
      </RecruitmentLinkContainer>
    </RecruitmentContainer>
  );
};

export default Recruitment;
