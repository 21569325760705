import React from 'react';
import styled from 'styled-components';
import { FlexContainer, FlexItem, HrLine } from '@/styles/Containers';
import { ExtraBold } from '@/styles/Text';
import DeleteReview from '@/components/review/DeleteReview';
import BanUser from './BanUser';
import Upvote from './Upvote';
import EditReview from './EditReview';
import ReportReview from './ReportReview';
import { TooltipButtonContainer } from '@/styles/Buttons';
import { TooltipText } from '../RateCourseButton';

interface ReviewProps {
  name: string;
  studyProgramme: string;
  score: number;
  workLoad: number | string | void;
  difficulty: number | string | void;
  text: string;
  date: string;
  isAdmin: boolean;
  canDelete: boolean;
  id: number;
  upvoteStatus: number;
  numUpvotes: number;
  reportStatus: number;
  setIdCommentEdit: React.Dispatch<React.SetStateAction<number>>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReviewContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.black};
  display: flex;
  margin: 2vh 0 2vh 0;
  padding: 12px 3% 12px 3%;
  border-radius: 10px;
`;

const ScoreDateContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0 0 10px 0;
`;

const OptionContainer = styled.div`
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
`;

const MainMetric = styled.div`
  padding: 5px 40px;
`;

const SecondaryMetric = styled.p`
  margin: 0 2vw 0 0;
  font-size: 16px;
  @media ${({ theme }) => theme.device.mobileL} {
    font-size: 14px;
  }
`;

const DateText = styled.p`
  font-family: poppinslight;
  margin: 0;
`;

const CommentText = styled.p`
  word-break: break-word;
  text-align: left;
`;

const DeleteEditContainer = styled.div`
  margin-left: auto;
`;

const Review: React.FC<ReviewProps> = ({
  name,
  studyProgramme,
  score,
  workLoad = -1,
  difficulty = -1,
  text,
  date,
  isAdmin,
  canDelete,
  id,
  upvoteStatus,
  numUpvotes,
  reportStatus,
  setIdCommentEdit,
  setEdit,
}) => {
  const dateObject = new Date(date);
  const europeanDate = `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear().toString().substr(-2)}`;
  let scoreLabelColor = 'transparent';
  // TODO: Make this more elegant? Possibly use themes instead for example?
  switch (score) {
    case 1:
      scoreLabelColor = '#F94144';
      break;
    case 2:
      scoreLabelColor = '#F8961E';
      break;
    case 3:
      scoreLabelColor = '#F9C74F';
      break;
    case 4:
      scoreLabelColor = '#A0C85A';
      break;
    default:
      scoreLabelColor = '#47C964';
  }

  const mapSecondaryMetric = (secondaryMetric: string | number) => {
    switch (secondaryMetric) {
      case 1:
        return 'middels';
      case 2:
        return 'høy';
      case 0:
        return 'lav';
      default:
        return 'ikke oppgitt';
    }
  };

  return (
    <ReviewContainer>
      <FlexItem flex='1' style={{ marginRight: '3%' }}>
        <div>
          <ExtraBold>{name}</ExtraBold>
          <div>{studyProgramme}</div>
          {/* Using div instead of p to avoid having to reduce line spacing */}
        </div>
      </FlexItem>
      <FlexItem flex='3'>
        <ScoreDateContainer>
          <MainMetric style={{ backgroundColor: scoreLabelColor }}>
            <ExtraBold>{score}/5</ExtraBold>
          </MainMetric>
          <DateText>{europeanDate}</DateText>
        </ScoreDateContainer>
        <FlexContainer flexWrap='wrap'>
          <SecondaryMetric>
            Arbeidsmengde: <ExtraBold>{mapSecondaryMetric(workLoad)}</ExtraBold>
          </SecondaryMetric>
          <SecondaryMetric>
            Vanskelighetsgrad: <ExtraBold>{mapSecondaryMetric(difficulty)}</ExtraBold>
          </SecondaryMetric>
        </FlexContainer>
        <HrLine margin='20px 0 0 0' />
        <FlexContainer width='100%'>
          <CommentText>{text}</CommentText>
        </FlexContainer>
        <OptionContainer>
          <TooltipButtonContainer style={{ display: 'inline-flex' }}>
            <Upvote reviewId={id} upvoteStatus={upvoteStatus} numUpvotes={numUpvotes} />
            <TooltipText>Hjelpsom anmeldelse</TooltipText>
          </TooltipButtonContainer>
          <DeleteEditContainer>
            {!canDelete && <ReportReview reviewId={id} reportStatus={reportStatus} />}
            {canDelete && (
              <TooltipButtonContainer>
                <EditReview id={id} setIdCommentEdit={setIdCommentEdit} setEdit={setEdit} />
                <TooltipText>Rediger anmeldelse</TooltipText>
              </TooltipButtonContainer>
            )}
            {canDelete && (
              <TooltipButtonContainer>
                <DeleteReview reviewId={id} />
                <TooltipText>Slett anmeldelse</TooltipText>
              </TooltipButtonContainer>
            )}
            {isAdmin && (
              <TooltipButtonContainer>
                <BanUser reviewId={id} userName={name} />
                <TooltipText>Slett bruker</TooltipText>
              </TooltipButtonContainer>
            )}
          </DeleteEditContainer>
        </OptionContainer>
      </FlexItem>
    </ReviewContainer>
  );
};

export default Review;
