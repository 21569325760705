import React from 'react';
import { FlexColumn, CarouselCardText, CarouselCardTitle, CarouselTextContainer } from '@/styles/Containers';

interface CarouselCardProps {
  picture: string;
  title: string;
  text: string;
  altText: string;
}

export const CarouselCard = ({ picture, title, altText, text }: CarouselCardProps) => {
  return (
    <FlexColumn style={{ minHeight: '22rem' }}>
      <img src={picture} alt={altText} width={'100px'} height={'100px'} />
      <CarouselTextContainer>
        <CarouselCardTitle>{title}</CarouselCardTitle>
        <CarouselCardText>{text}</CarouselCardText>
      </CarouselTextContainer>
    </FlexColumn>
  );
};
