import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/globalStyles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ContextProvider from './context/ContextProvider';
import { defaultTheme } from './styles/theme';
import Routes from './routes';
import { unregister } from './serviceWorker';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Recruitment from './components/Recruitment';

Sentry.init({
  dsn: 'https://a4a4d9e1a18b40aeb091d7a8805875e4@o348435.ingest.sentry.io/5954554',
  integrations: [new BrowserTracing()],
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ContextProvider>
        <ThemeProvider theme={defaultTheme}>
          <Recruitment />
          <GlobalStyle />
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </ContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// The service worker could safely be removed after a while
// firstly we have to unregister the active service workers
unregister();
