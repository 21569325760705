import React from 'react';
import { Link } from 'react-router-dom';
import { MePageCourseItemText } from '../../styles/Text';
import { SemesterRow, UnavailableSemesterRow } from '@/styles/Containers';

export interface MyCourseProps {
  courseName: string;
  courseCode: string;
  yourScore: string;
  hasReviewed: boolean;
  unavailable?: boolean;
}

const MyCourse = ({ courseName, courseCode, yourScore, hasReviewed, unavailable }: MyCourseProps) => {
  if (unavailable) {
    return (
      <UnavailableSemesterRow title={`${courseCode} - ${courseName}`}>
        <MePageCourseItemText textAlign='left'>{courseCode}</MePageCourseItemText>
        <MePageCourseItemText ellipsis textAlign='left'>
          {courseName}
        </MePageCourseItemText>
        <MePageCourseItemText ellipsis textAlign='center'>
          Utilgjengelig
        </MePageCourseItemText>
      </UnavailableSemesterRow>
    );
  }
  return (
    <SemesterRow as={Link} to={`/course/${courseCode}`} title={`${courseCode} - ${courseName}`}>
      <MePageCourseItemText textAlign='left'>{courseCode}</MePageCourseItemText>
      <MePageCourseItemText ellipsis textAlign='left'>
        {courseName}
      </MePageCourseItemText>
      {hasReviewed ? (
        <MePageCourseItemText textAlign='center'>{yourScore}</MePageCourseItemText>
      ) : (
        <MePageCourseItemText ellipsis textAlign='center' underlined={!hasReviewed}>
          Gi vurdering
        </MePageCourseItemText>
      )}
    </SemesterRow>
  );
};

export default MyCourse;
